import gql from 'graphql-tag'

export const LIST_TRELLO_CARD = (templateType) => gql`query LIST_TRELLO_CARD ($paperType: String!, $paperId: Int!) {
  items: list${templateType}TrelloCard (paperType: $paperType, paperId: $paperId) {
    id name pos description
    dueComplete dueAt updatedAt
    comments {
      id text createdBy
    }
  }
}`

export const CREATE_TRELLO_CARD = (templateType) => gql`mutation CREATE_TRELLO_CARD ($paperType: String!, $paperId: Int!, $input: PaperCoreTrelloCardInput!) {
  createCard: create${templateType}TrelloCard (paperType: $paperType, paperId: $paperId, input: $input)
}`

export const UPDATE_TRELLO_CARD = (templateType) => gql`mutation UPDATE_TRELLO_CARD ($paperType: String!, $paperId: Int!, $cardId: String!, $input: PaperCoreTrelloCardInput!) {
  updateCard: update${templateType}TrelloCard (paperType: $paperType, paperId: $paperId, cardId: $cardId, input: $input)
}`

export const DESTROY_TRELLO_CARD = (templateType) => gql`mutation DESTROY_TRELLO_CARD ($paperType: String!, $paperId: Int!, $cardId: String!) {
  destroyCard: destroy${templateType}TrelloCard (paperType: $paperType, paperId: $paperId, cardId: $cardId)
}`
