<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          <h5 class="">{{card.name}}</h5>
          <div class="pre-line" v-if="card.description">{{card.description}}</div>
          <div class="text-danger" v-if="card.dueAt">ครบกำหนด: {{card.dueAt | datetime}}</div>
          <ul v-if="card.comments.length > 0" class="mb-0">
            <li
              v-for="comment in card.comments"
              :key="comment.id">
              {{comment.createdBy}}
              <div class="">
                {{comment.text}}
              </div>
            </li>
          </ul>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="หัวข้อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <sgv-input-datetime
            class="col-12"
            label="ครบกำหนดวันที่"
            v-model="formData.dueAt">
          </sgv-input-datetime>

          <sgv-input-textarea
            class="col-12"
            label="รายละเอียดเพิ่มเติม"
            autoGrow
            v-model="formData.description">
          </sgv-input-textarea>
        </div>

        <button
          v-if="!cardId"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="cardId"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="cardId"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(cardId)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  CREATE_TRELLO_CARD,
  UPDATE_TRELLO_CARD,
  DESTROY_TRELLO_CARD
} from '../graph/trello'

export default {
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    paperId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    card: {
      type: Object,
      required: false
    },
    cardId: {
      type: String
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        description: '',
        dueAt: null
      }
    }
  },
  validations: {
    formData: {
      name: { required },
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'dueAt') {
          this.formData[key] = this.$date.format(v[key]).datetime
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (input) {
      return  {
        ...input,
        dueAt: this.$date.format(input.dueAt).raw
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_TRELLO_CARD(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.cancelData()
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_TRELLO_CARD(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          cardId: this.cardId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_TRELLO_CARD(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          cardId: this.cardId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', null)
    },
    emitUpdated () {
      this.toggle = false
      this.$emit('updated', null)
    }
  },
  watch: {
    toggle (value) {
      if (value && this.card) {
        this.setFormData(this.card)
      } else if (value) {
        this.formData.name = this.title || ''
      }
    }
  },
  created () {
    if (!this.cardId) {
      this.toggle = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
