<template>
  <div class="">
    <div class="form-row">
      <div class="col-auto">
        <button
          v-if="!showCreateForm"
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="showCreateForm = true">
          เพิ่ม
        </button>
      </div>
    </div>

    <DetailTrelloCard
      key="createForm"
      class="mb-3"
      v-if="showCreateForm"
      :title="title"
      :paperId="paperId"
      :paperType="paperType"
      :templateType="templateType"
      @cancel="showCreateForm = false"
      @updated="refetch">
    </DetailTrelloCard>

    <DetailTrelloCard
      class="mb-3"
      v-for="item in items"
      :key="item.id"
      :cardId="item.id"
      :card="item"
      :paperId="paperId"
      :paperType="paperType"
      :templateType="templateType"
      @updated="refetch">
    </DetailTrelloCard>
  </div>
</template>

<script>
import DetailTrelloCard from './DetailTrelloCard.vue'
import { LIST_TRELLO_CARD } from '../graph/trello'

export default {
  components: {
    DetailTrelloCard
  },
  props: {
    title: {
      type: String,
      required: false
    },
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      showCreateForm: false,
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_TRELLO_CARD(this.templateType)
      },
      variables () {
        return {
          paperType: this.paperType,
          paperId: this.paperId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.items.refetch()
    },
  }
}
</script>

<style lang="css" scoped>
</style>
